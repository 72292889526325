import {
  Component,
  Inject,
  ViewChild,
  TemplateRef,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ScheduleService } from '../../schedules/services/schedules.service';
import { LessonService } from '../../lessons/services/lessons.service';
import { ToasterService } from '../../../../services/toaster.services';
import { DialogBoxService } from '../../../../services/dialog-box.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { beforeRead } from '@popperjs/core';
import { LocalStorageService } from '../../../../services/ls-service';

interface Day {
  id: string;
  name: string;
  term: string;
  children: Period[];
}
interface Period {
  id: string;
  name: string;
  type: string;
  order: number;
  start_time: string;
  end_time: string;
  class_infos: any;
  merge: any;
  selected: boolean;
}
@Component({
  selector: 'app-block-schedule-calendar',
  templateUrl: './block-schedule-calendar.component.html',
  styleUrl: './block-schedule-calendar.component.scss',
})
export class BlockScheduleCalendarComponent {
  @ViewChild('DayDialogTemplate') DayDialogTemplate!: TemplateRef<any>;
  @Output() menuClosed: EventEmitter<void> = new EventEmitter<void>();
  @Input() blockid: string = '';
  @ViewChild(MatMenuTrigger) menuCellOptionsTrigger!: MatMenuTrigger;
  dialogRef: MatDialogRef<any> | undefined;
  constructor(
    private scheduleService: ScheduleService,
    private lessonService: LessonService,
    private toaster: ToasterService,
    private dialogBoxService: DialogBoxService,
    private route: ActivatedRoute,
    private router: Router,
    private ls: LocalStorageService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogBlockSched: MatDialogRef<BlockScheduleCalendarComponent>
  ) {}
  blockidchecker = this.route.snapshot.params['blockid'];
  blockiddata = this.data;
  schedid = this.blockiddata?.sched_id
    ? this.blockiddata?.sched_id
    : this.route.snapshot.params['schedid'];
  days: Day[] = [];
  displayedColumns: string[] = ['period'];
  inputDayPeriod: string = '';
  inputDayPeriodStartTime: string = '';
  inputDayPeriodEndTime: string = '';
  inputDayId: string = '';
  selectedCells = new Set<string>();
  selection: any[] = [];
  multiselectedPeriods: any[] = [];
  classes: any[] = [];
  lessons: any[] = [];
  selectedClass: any = null;
  selectedLesson: any = null;
  selectedPeriod: any = null;
  inputClassName: string = '';
  createdClass: string = '';
  logfrom: number = 0;
  logto: number = 0;
  start: number = 0;
  end: number = 0;
  frontEndNames = this.ls.getFrontEndNames();

  onMenuClosed(): void {
    this.days.map((day: any) => {
      day.children.map((period: any) => {
        period.selected = false;
      });
    });
  }
  ngOnInit(): void {
    this.ngOnChanges();
  }
  ngOnChanges() {
    this.blockid = this.blockiddata?.blockschedid
      ? this.blockiddata?.blockschedid
      : this.blockidchecker
      ? this.blockidchecker
      : this.blockid;
    if (this.blockid !== undefined) {
      this.scheduleService
        .getBlockSchedule(this.blockid)
        .then((response: any) => {
          this.days = response.children;
          this.days = this.days.map((day: any) => {
            const periodsWithSelected = day.children.map((period: any) => {
              return {
                ...period,
                selected: false,
              };
            });
            return {
              ...day,
              children: periodsWithSelected,
            };
          });
          this.displayedColumns = [
            'period',
            ...this.days.map((day) => day.name),
          ];
        });
      if (this.blockiddata?.blockschedid !== undefined) {
        this.classes[0] = {
          name: this.blockiddata?.class_name,
          id: this.blockiddata?.class_info,
          lesson: {
            color: this.blockiddata?.color,
          },
        };
        this.selectedClass = this.blockiddata?.class_info;
      } else {
        this.scheduleService
          .getScheduleDetail(this.schedid)
          .then((response: any) => {
            this.classes = response.classes;
          });
        this.lessonService.getLessons().then((response: any) => {
          this.lessons = response.results;
        });
      }
    }
  }
  getPeriodTime(day: Day, periodOrder: number): string {
    const period = day.children.find((p) => p.order === periodOrder);
    return period ? `${period.start_time} - ${period.end_time}` : '';
  }

  handleClickCell(rowIndex: number, colIndex: number): void {
    this.inputDayPeriod = '';
    if (rowIndex === 0) {
      this.inputDayPeriod = this.days[colIndex - 1].name;
    } else {
      this.inputDayPeriod = this.days[0].children[rowIndex - 1].name;
      this.inputDayPeriodStartTime =
        this.days[0].children[rowIndex - 1].start_time;
      this.inputDayPeriodEndTime = this.days[0].children[rowIndex - 1].end_time;
      this.selectedPeriod = this.days[0].children[rowIndex - 1];
    }
    if (colIndex > 0) {
      this.inputDayId = this.days[colIndex - 1].id;
    }
  }

  handleSelectedCell(element: Day, column: string): boolean {
    return this.selectedCells.has(`${element.children}-${column}`);
  }

  openDialog(templateRef: TemplateRef<any>, data: any): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.data = data;
    this.dialogRef = this.dialog.open(templateRef, { data });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.selectedClass = this.blockiddata?.class_info
        ? this.blockiddata?.class_info
        : this.createdClass;
    });
  }

  actionBlockDayPeriod(
    option: number,
    type: string,
    others: { [key: string]: any } = {}
  ): void {
    switch (type) {
      case 'day':
        if (option === 1) {
          const data = {
            block_schedule: this.blockid,
          };
          this.scheduleService
            .createBlockScheduleDay(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Day successfully added.',
                  5000
                );
                this.ngOnChanges();
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show('error', 'Error', 'Unable to add a day.', 5000);
            });
        } else if (option === 2) {
          if (!this.inputDayPeriod) {
            this.toaster.show(
              'error',
              'Error',
              'Please enter a day label.',
              5000
            );
            return;
          }
          const data = {
            name: this.inputDayPeriod,
          };
          this.scheduleService
            .updateBlockScheduleDay(this.blockid, this.inputDayId, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Day successfully updated.',
                  5000
                );
                this.ngOnChanges();
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to update a day.',
                5000
              );
            });
        } else if (option === 3) {
          this.scheduleService
            .deleteBlockScheduleDay(this.blockid, this.inputDayId)
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Day successfully removed.',
                5000
              );
              this.ngOnChanges();
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to remove a day.',
                5000
              );
            });
        }
        break;
      case 'period':
        if (option === 1) {
          const data = {
            block_schedule: this.blockid,
          };
          this.scheduleService
            .createBlockSchedulePeriod(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Period successfully added.',
                  5000
                );
                this.ngOnChanges();
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to add a period.',
                5000
              );
            });
        } else if (option === 2) {
          if (!this.inputDayPeriod) {
            this.toaster.show(
              'error',
              'Error',
              'Please enter a day label.',
              5000
            );
            return;
          }
          const data = {
            periods: [this.selectedPeriod.id],
            name: this.inputDayPeriod,
            order: this.selectedPeriod.order,
            start_time: this.selectedPeriod.start_time,
            end_time: this.selectedPeriod.end_time,
          };
          this.scheduleService
            .updateBlockSchedulePeriod(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Period successfully updated.',
                  5000
                );
                this.ngOnChanges();
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to update a period.',
                5000
              );
            });
        } else if (option === 3) {
          this.scheduleService
            .deleteBlockSchedulePeriod(this.blockid)
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Period successfully removed.',
                5000
              );
              this.ngOnChanges();
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to remove a period.',
                5000
              );
            });
        } else if (option === 4) {
          const [startHour, startMinute] = this.inputDayPeriodStartTime
            .split(':')
            .map(Number);
          const [endHour, endMinute] = this.inputDayPeriodEndTime
            .split(':')
            .map(Number);

          const startDate = new Date();
          startDate.setHours(startHour, startMinute);

          const endDate = new Date();
          endDate.setHours(endHour, endMinute);
          if (!this.inputDayPeriodStartTime || !this.inputDayPeriodEndTime) {
            this.toaster.show(
              'error',
              'Error',
              'Please check start and end time. Fields are required.',
              5000
            );
            return;
          } else {
            if (startDate > endDate) {
              this.toaster.show(
                'error',
                'Error',
                'Please enter a valid start and end time.',
                5000
              );
              return;
            }
          }
          const data = {
            periods: [this.selectedPeriod.id],
            name: this.selectedPeriod.name,
            order: this.selectedPeriod.order,
            start_time: this.inputDayPeriodStartTime,
            end_time: this.inputDayPeriodEndTime,
          };
          this.scheduleService
            .updateBlockSchedulePeriod(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Period successfully updated.',
                  5000
                );
                this.ngOnChanges();
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to update a period.',
                5000
              );
            });
        }
        break;
      case 'class':
        if (option === 1) {
          if (!this.selectedClass) {
            this.toaster.show(
              'error',
              'Error',
              'Class info is required.',
              5000
            );
            return;
          }
          const data = {
            periods: [others['period'].id],
            class_info: this.selectedClass,
          };
          this.scheduleService
            .assignBlockScheduleClass(this.blockid, this.inputDayId, data)
            .then((response: any) => {
              if (response.class_info) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Classes asssigned successfully.',
                  5000
                );
              } else {
                this.toaster.show(
                  'error',
                  'Error',
                  'Error assigning classes. Please contact administrator.',
                  5000
                );
              }
              this.ngOnChanges();
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Cannot assign the same class on the same day.',
                5000
              );
            });
        } else if (option === 2) {
          const data = {
            class_info_id: others['period'].class_infos[0].id,
          };
          this.scheduleService
            .removeBlockScheduleClass(this.blockid, this.inputDayId, data)
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Class unassigned successfully.',
                5000
              );
              this.ngOnChanges();
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to unassign a class.',
                5000
              );
            });
        } else if (option === 3) {
          const merge_ids = this.multiselectedPeriods.map(
            (period) => period.id
          );
          const data = {
            merge_nodes: merge_ids,
          };
          this.scheduleService
            .mergeBlockSchedulePeriod(this.blockid, this.inputDayId, data)
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Periods merged successfully.',
                5000
              );
              this.ngOnChanges();
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to merged periods.',
                5000
              );
            });
        } else if (option === 4) {
          const data = {
            merge_id: others['period'].merge.id,
          };
          this.scheduleService
            .unmergeBlockSchedulePeriod(this.blockid, this.inputDayId, data)
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Periods unmerged successfully.',
                5000
              );
              this.ngOnChanges();
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to unmerged periods.',
                5000
              );
            });
        } else if (option === 5) {
          if (!this.selectedClass) {
            this.toaster.show(
              'error',
              'Error',
              'Class info is required.',
              5000
            );
            return;
          }
          const data = {
            periods: [this.multiselectedPeriods[0].id],
            class_info: this.selectedClass,
          };
          this.scheduleService
            .assignBlockScheduleClass(this.blockid, this.inputDayId, data)
            .then((response: any) => {
              if (response.class_info) {
                const merge_ids = this.multiselectedPeriods.map(
                  (period) => period.id
                );
                const data = {
                  merge_nodes: merge_ids,
                };
                this.scheduleService
                  .mergeBlockSchedulePeriod(this.blockid, this.inputDayId, data)
                  .then((response: any) => {
                    this.toaster.show(
                      'success',
                      'Success',
                      'Class assigned and periods merged successfully.',
                      5000
                    );
                    this.ngOnChanges();
                    if (this.dialogRef) {
                      this.dialogRef.close();
                    }
                  })
                  .catch((error) => {
                    this.toaster.show(
                      'error',
                      'Error',
                      'Unable to merged periods.',
                      5000
                    );
                  });
              } else {
                this.toaster.show(
                  'error',
                  'Error',
                  'Error assigning classes. Please contact administrator.',
                  5000
                );
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Cannot assign the same class on the same day.',
                5000
              );
            });
        } else if (option === 6) {
          if (!this.selectedLesson) {
            this.toaster.show(
              'error',
              'Error',
              'Lesson info is required.',
              5000
            );
            return;
          }
          if (!this.inputClassName) {
            this.toaster.show(
              'error',
              'Error',
              'Class name is required.',
              5000
            );
            return;
          }
          this.lessonService
            .getLessonDetailsById(this.selectedLesson)
            .then((response: any) => {
              const subjectId = response.subject.id;
              const classFormData = {
                name: this.inputClassName,
                lesson: this.selectedLesson,
                subject: subjectId,
              };
              this.lessonService
                .createClassesOnSchedule(this.schedid, classFormData)
                .then((response: any) => {
                  this.createdClass = response.id;
                  const formData = {
                    lesson: {
                      id: this.selectedLesson,
                    },
                    term: {
                      id: this.schedid,
                    },
                    class_info: this.createdClass,
                  };
                  this.lessonService
                    .createPlannerbyLessonIdTermId(formData)
                    .then((response: any) => {
                      this.toaster.show(
                        'success',
                        'Success',
                        'Class is created successfully.',
                        5000
                      );
                      this.ngOnChanges();
                      if (this.dialogRef) {
                        this.dialogRef.close();
                      }
                      this.inputClassName = '';
                      this.selectedLesson = '';
                      console.log(others);
                      if (others['option'] === 1) {
                        this.openDialog(this.DayDialogTemplate, {
                          title: 'Assign Class',
                          option: 1,
                          type: 'class',
                          data: others['data'],
                        });
                      } else {
                        this.openDialog(this.DayDialogTemplate, {
                          title: 'Merge Periods',
                          option: 5,
                          type: 'class',
                          data: others['data'],
                        });
                      }
                    });
                });
            });
        }
        break;
      default:
        return;
        break;
    }
  }
  handleEnterKey(event: KeyboardEvent, option: number, type: string): void {
    if (event.key === 'Enter') {
      this.actionBlockDayPeriod(option, type);
      return;
    }
  }
  onSelectionStart(rowIndex: number, colIndex: number): void {
    this.days[colIndex].children[rowIndex].selected =
      !this.days[colIndex].children[rowIndex].selected;
    this.logfrom = rowIndex;
  }
  onSelectionEnd(rowIndex: number, colIndex: number) {
    this.onMenuClosed();
    this.logto = rowIndex;
    this.multiselectedPeriods = [];
    if (this.logfrom != this.logto) {
      this.start = this.logfrom < this.logto ? this.logfrom : this.logto;
      this.end = this.logto > this.logfrom ? this.logto : this.logfrom;
      for (let i = this.start; i <= this.end; i++) {
        this.days[colIndex].children[i].selected = true;
        this.multiselectedPeriods.push(this.days[colIndex].children[i]);
      }
    }
    this.inputDayId = this.days[colIndex].id;
  }
  optionsBlockSchedule(option: number): void {
    switch (option) {
      case 1:
        this.scheduleService
          .clearBlockSchedule(this.blockid, true)
          .then((response: any) => {
            this.toaster.show(
              'success',
              'Success',
              'Start over successfull.',
              5000
            );
            this.ngOnChanges();
          })
          .catch((error) => {
            this.toaster.show(
              'error',
              'Error',
              'Error encountered. Please contact the administration',
              5000
            );
          });
        if (this.dialogRef) {
          this.dialogRef.close();
        }
        break;
      case 2:
        this.scheduleService
          .clearBlockSchedule(this.blockid, false)
          .then((response: any) => {
            this.toaster.show(
              'success',
              'Success',
              'Classes are cleared successfully',
              5000
            );
            this.ngOnChanges();
          })
          .catch((error) => {
            this.toaster.show(
              'error',
              'Error',
              'Error encountered. Please contact the administration',
              5000
            );
          });
        if (this.dialogRef) {
          this.dialogRef.close();
        }
        break;
      default:
        break;
    }
  }
  deleteBlockSchedule(): void {
    const action = () => {
      this.scheduleService
        .deleteBlockSchedule(this.blockid)
        .then((response: any) => {
          this.toaster.show(
            'success',
            'Success',
            'Schedule deleted successfully',
            5000
          );
          this.ngOnChanges();
          const currentUrl = this.router.url;
          if (this.dialogBlockSched.id) {
            this.dialogBlockSched.close();
            this.router
              .navigateByUrl(`/lesson-planner/schedule/${this.schedid}`, {
                skipLocationChange: false,
              })
              .then(() => {
                this.router.navigate([currentUrl]);
              });
          } else {
            this.router
              .navigateByUrl('/', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate([currentUrl]);
              });
          }
        })
        .catch((error) => {
          this.toaster.show(
            'error',
            'Error',
            'Error encountered. Please contact the administration',
            5000
          );
        });
      this.dialogBoxService.closeConfirmationBox();
    };
    this.dialogBoxService.openConfirmationDialog({
      title: 'Delete schedule',
      body: `Are you sure you want to delete this schedule? <br/>
       The contents of the schedule can not be restored after
      deletion`,
      action: action,
    });
  }

  goBack() {
    history.back();
  }

  goTo(url: string) {
    if (this.blockiddata?.blockschedid !== undefined) {
      this.router.navigate([
        '/lesson-planner/schedule/' + this.blockiddata.sched_id,
      ]);
      setTimeout(() => {
        this.router.navigate([url]);
      }, 10);
      setTimeout(() => {
        this.blockiddata.action();
      }, 1000);
      return;
    }
    this.router.navigate([url]);
  }
  formatTimeTo12Hour(timeString: string) {
    // Parse the time string to a Date object
    const date = new Date(`1970-01-01T${timeString}Z`);

    // Format the time to 12-hour format with AM/PM
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date;
  }
}
